import React, { useState } from 'react';
import clsx from 'clsx';
import useAuthContext from 'hooks/useAuthContext';
import styled from '@emotion/styled';
import { camelToNormal , copyToClipboard} from 'utils/string';
import { Button, Icon } from 'components';

interface MobileMembersTableItemProps {
  data: WorkspaceMember;
  message: (e: React.MouseEvent<HTMLButtonElement>) => void;
  edit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  remove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const MobileMembersTableItem: React.FC<MobileMembersTableItemProps> = ({ data, remove, edit, message }) => {
  const { businessPhone, secondaryEmail } = data;
  const [open, setOpen] = useState(false);
  const { authData } = useAuthContext();
  return (
    <StyledTableRow className={clsx('member', { open })}>
      <div onClick={() => setOpen(v => !v)} className="member__header">
        <span className="member__col">
          <Icon name="plus-mini" size={10} />
        </span>
        {/* <span className="member__col">
          <img src={data.photo || imagePlaceholder} alt="member-photo" className="member__photo" />
        </span> */}
        <span  onClick={(e) => {copyToClipboard(e)}} className="member__col name">{data.name}</span>
        <span className="member__col role">{camelToNormal(data.role)}</span>
      </div>
      {open && (
        <div className="member__content">
          {data.companyName && (
            <div className="member__line">
              <span>{data.companyName||data.contactPerson}</span>
            </div>
          )}
          <div className="member__line">
            <Icon name="email" />
            <span>{<span onClick={(e) => {copyToClipboard(e)}}>{data.email}</span> || <span className="no-info">No information</span>}</span>
          </div>
          <div className="member__line">
            <Icon name="smartphone" />
            <span>{<a href={"tel:" + data.phone}>{data.phone}</a> || <span className="no-info">No information</span>}</span>
          </div>
          <div className="member__line">
            <Icon name="phone" />
            <span>{businessPhone || <span className="no-info">No information</span>}</span>
          </div>
          <div className="member__line">
            <Icon name="home" size={16} />
            <span>{data.address1 || <span className="no-info">No address</span>}</span>
          </div>
          <div className="member__line">
            <span>{secondaryEmail || <span className="no-info">No information</span>}</span>
          </div>

          {authData?.superAdmin && <div className="members__btn-group mobile">
            <Button onClick={message} className="member__btn">Send message</Button>
            <Button onClick={edit} className="member__btn">
              Edit user
            </Button>
            <Button secondary onClick={remove} className="member__btn">
              ✗ Remove
            </Button>
          </div>}
        </div>
      )}
    </StyledTableRow>
  );
};

export default MobileMembersTableItem;

const StyledTableRow = styled.tbody`
  height: 16px;

  &:not(:last-of-type) {
    .member__header {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }

  .no-info {
    color: #545454;
  }

  &.open .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }

  .member {
    &__header {
      display: flex;
      align-items: center;
      padding: 8px 0;
    }
    &__content {
      margin: 0 0 16px;
      padding: 16px 0;
    }
    &__col {
      padding: 0 8px;
      &.name,
      &.role {
        flex: 2;
      }
    }
    &__line {
      display: flex;
      margin-bottom: 16px;
      position: relative;
      span:not(.no-info) {
        padding-left: 34px;
      }
      .icon {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &__photo {
      width: 24px;
      height: 24px;
      object-fit: contain;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__btn {
      height: 24px;
    }
  }
  .role {
    display: inline-block;
    margin-left: 54px;
  }
`;
